<template>
<div class=" wrapper"   v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-header">
        <div>
            <h1> {{$route.query.district}} {{$t('building_list').toLowerCase()}}</h1>
        </div>
        <div class="flex align-center">
            <button class="back-button" @click="$router.push(`/?page=4&${router_link}`)">{{$t('back')}}</button> 
        </div>
    </div>
    <div class="wp-body obj-list">
        <header class="wp-header flex flex-col ">
            <div class="cover flex justify-between items-center mb-3 pr-3 w-full">
                <div>
                    <p class="fw-600 fs-20 c-dark-blue">{{filtertype[seltype-1].label}} {{$t('building_list').toLowerCase()}}:</p>
                </div>
                <div class="flex items-center justify-end  bb fw-w" style="flex:1; padding-left:50px; max-width:300px;">
                    <el-select :placeholder="$t('building_category')" class="mt-2 mb-2 mw-100"   @change="__GET()" v-model="seltype"   clearable>
                        <el-option v-for="(item, i) in filtertype" :key="i" :label="item.label" :value="i+1">
                        </el-option>
                    </el-select>
                    
                </div>
            </div>
        </header>

        <el-table :data="buildsList" class="mt-5" style="width: 100%; margin-bottom: 20px;" row-key="id" stripe default-expand-all>
            <el-table-column label="№" width="50" align="center">
                <template slot-scope="scope">
                    {{ (current_page-1)*10 + (scope.$index + 1) }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('building_name')" align="center" width="150" sortable>
                <template slot-scope="scope">
                    <button @click="routeTo(scope.row, 0)" class="table-link-button"> {{scope.row.name_cyr? scope.row.name_cyr: '***'}}  </button>
                </template>
            </el-table-column>
            <el-table-column v-if="me.role.name == 'admin'" prop="cadastral_number" :label="$t('cadaster_number')" align="center" sortable>
            </el-table-column>
            <el-table-column :label="$t('building_address')" prop="address" align="center" sortable>
            </el-table-column>
            <el-table-column :label="$t('created_at')" prop="created_at" align="center" sortable>
            </el-table-column>
            <el-table-column :label="$t('year_construction')" prop="year_construction" align="center" sortable>
            </el-table-column>
            <el-table-column prop="finalconclusion" :label="$t('finalconclusion')" align="center" sortable>
                <template slot-scope="scope">
                    <p :class="parseInt(scope.row.finalconclusion) > 2 ? parseInt(scope.row.finalconclusion) == 3 ? 'c-yellow' : 'c-red':'c-green'">{{scope.row.finalconclusion}}</p>
                </template>
            </el-table-column>
            <el-table-column :label="$t('building_type')" prop="" align="center" sortable width="130px">
                <template slot-scope="scope">
                    <el-tag :class="buildingtype === 'NEW' ? 'new_build' : 'exist_build'" style="width: 120px; cursor: pointer" size="small" :type="buildingtype === 'NEW' ? 'success' : 'info'">{{ scope.row.buildingtype === 'NEW' ? $t('new_building') : $t('existing_building') }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column :label="$t('created_by')" align="center" sortable>
                <template slot-scope="scope">
                    <div class="table-link-button" @click="UserModal(scope.row.created_by)">
                        {{scope.row.created_by!=null && scope.row.created_by.profile!=null ? scope.row.created_by.profile.name+" "+scope.row.created_by.profile.surname : '-' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('updated_by')" align="center" sortable>
                <template slot-scope="scope">
                    <div class="table-link-button" @click="UserModal(scope.row.updated_by)">
                        {{scope.row.updated_by!=null && scope.row.updated_by.profile!=null ? scope.row.updated_by.profile.name +" "+ scope.row.updated_by.profile.surname : '-' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="role == 'admin' ?  $t('passport_one')+' / '+$t('statistic'):$t('statistic')" width="180" align="center">
                <template slot-scope="scope">
                    <div class="flex justify-center">
                        <div v-if="role == 'admin'">
                            <el-button   @click="GeneratePasport(scope.row.id)" class="el-button-download el-icon-s-order"> 
                            </el-button> 
                        </div>
                        <button class="ml-2 el-button-download el-icon-download" size="small" @click="DownloadExel(scope.row.id, scope.row.name_cyr)" > 
                        </button>
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column align="center" :label="$t('see')">
                <template slot-scope="scope">
                    <div class="d-flex justify-center">
                        <el-button @click="routeTo(scope.row, '0')" size="small" style="display: flex; align-items: center; justify-content: flex-start; " class="el-icon-view" type="primary" plain></el-button>
                    </div>
                </template>
            </el-table-column> -->
            <el-table-column align="center" :label="$t('change')" v-if="role!='admin' && role !== 'org_admin' && role!=='ministry_admin' ">
                <template slot-scope="scope">
                    <div class="d-flex justify-center" v-if="!(role=='operator' && scope.row.buildingtype == 'NEW')">
                        <el-button @click="routeTo(scope.row, '1')" size="small"  class="el-icon-edit el-button-download"></el-button>
                    </div>
                </template>
            </el-table-column>

        </el-table>
        <!-- <el-pagination v-if="page_count" style="margin-left: auto; margin-top: auto" class="ml-auto" @current-change="paginate" :current-page.sync="current_page" :hide-on-single-page="true" background layout="prev, pager, next" :page-count="page_count">
        </el-pagination> -->
        <el-pagination style="margin-left: auto; margin-top: auto" class="ml-auto" :page-sizes="[10, 20, 50, 100]" :page-size="5" @current-change="paginate" @size-change="paginateSize" :current-page.sync="current_page1" :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="page_count">
        </el-pagination>
        <el-drawer :title="$t('building_passport')" :visible.sync="drawer" direction='rtl'>
            <div>
                <div class="pdf">
                    <vue-pdf ref="print" :src="pdf"></vue-pdf>
                    <div class="loading-pdf" v-show="loadDawer">
                        <i class="el-icon-loading"></i>
                    </div>
                </div>
                <div class="w-full pdf-control">
                    <button class="button dark-yellow" @click="downloadFile(pdf)"><i class="el-icon-download mr-2"></i> {{$t('download')}}</button>
                    <a :href="pdf" target="_blank">
                        <button class="ml-3 button dark-blue"><i class="el-icon-view mr-2"></i> {{$t('see')}}</button>
                    </a>
                </div>
            </div>
        </el-drawer>
        <div class="modal fs-14" v-if="usermodal">
            <div class="modal-cover"></div>
            <div class="modal-item" style="max-width:500px;">
                <div class="flex justify-between">
                    <p class="modal-title ">{{$t('user_date')}}:</p>
                    <button style="font-size:16px; " @click="usermodal=false"><i class="el-icon-close" style="font-weight:600;"></i> </button>
                </div>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('first_name')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.name}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('last_name')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.surname}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('mid_name')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.middlename}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('phone_munber')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.phone_number}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('user_email')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.email ? userData.profile.email : '-'}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('organisation_name')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.organization && userData.organization.name_cyr ? userData.organization.name_cyr : '-'}}</el-col>
                </el-row>

                <div class="w-full modal-inputes flex justify-end">
                    <button @click="usermodal=false" style="padding:15px 15px;"> <i class="el-icon-close mr-2"></i> {{$t('close')}}</button>
                </div>
            </div>
        </div>  
    </div>
</div>
</template>

<script>
import Moment from 'moment';
import {
    extendMoment
} from 'moment-range';
const moment = extendMoment(Moment);
export default {
    data() {
        return {
            input2: null,
            drawer: false,
            pdf: '',
            namePdf: '',
            loadDawer: false,
            page_count: 0,
            buildsList: [],
            buildinggroup: '',
            current_district: [],
            objInfo: {}, 
            item_count: 10,
            loading: false,
            current_page: 1,
            current_page1: 1,
            buildingtype: '',
            districts: [],
            usermodal: false,
            userData: {
                organization: {},
                profile: {}
            },
            filtertype:[
                {type:'', label:this.$t('all_buildings')},
                {type:'1,2', label:this.$t('sustainable_buildings')},
                {type:'3', label:this.$t('not_sustainable_buildings')},
                {type:'4', label:this.$t('not_sustainable_buildings4')},
            ],
            seltype:1,
            router_link:'page=4'
        };
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        role() {
            return this.$store.state.role;
        },
        regions() {
            return this.$store.state.dictionary.region;
        },
        building_group() {
            return this.$store.state.dictionary.buildinggroup;
        }
    },

    mounted() {
        this.router_link=`org_id1=${this.$route.query.org_id1?this.$route.query.org_id1 :''}&org_id2=${this.$route.query.org_id2?this.$route.query.org_id2 :''}&region=${this.$route.query.region?this.$route.query.region :0}`
        this.current_page = this.$route.query.page ? parseInt(this.$route.query.page): 1   
        this.item_count = this.$route.query.item_count ? parseInt(this.$route.query.item_count) : 10
        this.seltype=this.$route.query.type? parseInt(this.$route.query.type):1
        this.__GET();
    },
    methods: {
        GeneratePasport(id) {
            this.loading = true
            this.loadDawer = true
            axios.post(`/building/specification/${id}/generate/`)
                .then(res => { 
                    this.drawer = true
                    this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link

                })
                .catch(e => {

                })
                .finally(() => {
                    this.loadDawer = false
                    this.loading = false

                })
        },
        downloadFile(file) {
            fetch(file)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    // the filename you want
                    a.download = 'PasportBino' || "file.json";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
        },
        paginate(val) {
            this.current_page = val;
            this.__GET()
        },
        paginateSize(val) {
            this.item_count = val
            this.current_page = 1

            this.__GET();
        },

        __GET() { 
            let offset = this.item_count * (this.current_page - 1);
            if ( this.seltype!=parseInt(this.$route.query.type) || this.item_count == 10) {

                this.$router.push(`/statistica/regionbuildings/${this.$route.params.region_id}/${this.$route.params.district_id}/${this.$route.params.org2}?type=${this.$route.query.type}&district=${this.$route.query.district}&page=${this.current_page}&item_count=${this.item_count}`)
            } 
            else{
                this.$router.push(`/statistica/regionbuildings/${this.$route.params.region_id}/${this.$route.params.district_id}/${this.$route.params.org2}&district=${this.$route.query.district}&page=${this.current_page}&item_count=${this.item_count}`)

            }
            let region_dis=this.$route.params.region_id!='0' ?`district=${this.$route.params.district_id}`:`region_id=${this.$route.params.district_id}`
            var link = `/building/specifications/?organization=${this.$route.params.org2!=0?this.$route.params.org2:''}&finalconclusion_id_in=${this.filtertype[this.seltype-1].type}&${region_dis}&limit=${this.item_count}&offset=${offset}`
            axios.get(link)
                .then((response) => {
                    this.buildsList = response.data.results;
                    this.page_count = Math.ceil(response.data.count / this.item_count);
                    this.current_page1 =this.$route.query.page ?  parseInt(this.$route.query.page) :  parseInt(this.$route.query.page)

                })
                .finally(() => {
                    this.loading = false
                })

        },
        routeTo(item, a) {
            if (item.buildingtype === "NEW") {
                this.$router.push({
                    path: `/new-build/${item.id}/${a}`,
                    params: {
                        id: this.$route.params.id
                    },
                    query: {
                        id: item.id
                    },
                });
            } else {
                this.$router.push({
                    path: `/exist-build/${item.id}/${a}`,
                    params: {
                        id: this.$route.params.id
                    },
                    query: {
                        id: item.id
                    },
                });
            }
        },
        getDistrict() {
            this.loading = true;
            axios.get(`/dictionary/district/?region__shared_id=${this.region}`)
                .then((response) => {

                    this.districts = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
         
        DownloadExel(id, name) {
            axios({
                url: `/building/generate/${id}/building/`,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', `${name}-Bino-${id}.xlsx`);
                document.body.appendChild(fileLink);

                fileLink.click();
            }); 
        },
        UserModal(a) {
            if (a != null && a.profile != null) {
                this.userData = a
                this.usermodal = true
            }
        },
         
    }
};
</script>

<style lang="scss" scoped>
.top-header {
    width: 100%;
    padding: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-header h1 {
    font-weight: 700;
    font-size: 25px;
    color: #2f525a !important;
}

.title-second-header {
    font-size: 18px;
    font-weight: 600;
    color: #345a63 !important;
}


</style>
